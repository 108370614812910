import React, { useContext, useEffect, useState } from "react";
import { FaRightLong } from "react-icons/fa6";
import { UserRole } from "../../../utils/enums";
import {
  Donor,
  EducationalInstitution,
  FinancialInstitution,
  Mentor,
  Student,
} from "../../registration";
import CommonNavBar from "../../common/CommonNavBar";
import "./register.css";
import RegistrationSuccess from "../../common/RegistrationSuccess";
import { RegistrationDataContext } from "../../../context/RegistraionContext";
import OrderPayment from "components/common/OrderPayment";

type props = {
  resetAddUsers?: Function;
};
const Register = ({ resetAddUsers = () => {} }: props) => {
  const { selectedRegistrationStep, setSelectedRegistrationStep, isAdminFlow } =
    useContext(RegistrationDataContext);

  const addText = isAdminFlow ? "ADD A" : "REGISTER AS A";

  useEffect(() => {
    setSelectedRegistrationStep("");
  }, []);
  const renderRegisterContent = () => {
    switch (selectedRegistrationStep) {
      case UserRole.STUDENT:
        return <Student />;
      case UserRole.DONOR:
        return <Donor />;
      case UserRole.MENTOR:
        return <Mentor />;
      case UserRole.EDUCATIONAL_INSTITUTION:
        return <EducationalInstitution />;
      case UserRole.FINANCIAL_INSTITUTION:
        return <FinancialInstitution />;
      case "success":
        return <RegistrationSuccess />;
      case "orderPayment":
        return <OrderPayment />;

      default:
        return (
          <div
            className="fixed-height m-0 p-0 relative overflow-hidden  flex justify-center items-center bg-white"
            style={{
              height: "calc(100vh-64px)",
            }}
          >
            <div className="flex flex-col justify-start gap-2.5 text-black text-sm  md:text-xl  font-semibold w-full md:w-1/3 bg-white px-6  py-4 md:py-2  md:rounded-xl">
              <div className="font-bold mb-4">YOU CAN {addText} </div>
              <div className="flex items-center gap-2.5">
                <span className="flex gap-2 items-center">
                  <FaRightLong />
                </span>
                <span
                  className="text-white cursor-pointer  bg-mainFont  hover:bg-mainFontHover px-3 py-0.5 rounded-lg"
                  onClick={() => setSelectedRegistrationStep(UserRole.STUDENT)}
                >
                  STUDENT
                </span>
              </div>
              <div className="flex items-center gap-2.5">
                <span className="flex gap-2 items-center">
                  <FaRightLong />
                </span>
                <span
                  className="text-white cursor-pointer  bg-mainFont  hover:bg-mainFontHover px-3 py-0.5 rounded-lg"
                  onClick={() => setSelectedRegistrationStep(UserRole.MENTOR)}
                >
                  MENTOR
                </span>
              </div>
              <div className="flex items-center gap-2.5">
                <span className="flex gap-2 items-center">
                  <FaRightLong />
                </span>
                <span
                  className="text-white cursor-pointer   bg-mainFont  hover:bg-mainFontHover px-3 py-0.5 rounded-lg"
                  onClick={() =>
                    setSelectedRegistrationStep(
                      UserRole.EDUCATIONAL_INSTITUTION
                    )
                  }
                >
                  EDUCATIONAL INSTITUTION
                </span>
              </div>
              <div className="flex items-center  gap-2.5">
                <span className="flex gap-2 items-center">
                  <FaRightLong />
                </span>
                <span
                  className="text-white cursor-pointer   bg-mainFont hover:bg-mainFontHover px-3 py-0.5 rounded-lg"
                  onClick={() =>
                    setSelectedRegistrationStep(UserRole.FINANCIAL_INSTITUTION)
                  }
                >
                  FINANCIAL INSTITUTION
                </span>
              </div>
              <div className="flex items-center gap-2.5">
                <span className="flex gap-2 items-center">
                  <FaRightLong />
                </span>
                <span
                  className="text-white cursor-pointer  bg-mainFont hover:bg-mainFontHover px-3 py-0.5 rounded-lg"
                  onClick={() => setSelectedRegistrationStep(UserRole.DONOR)}
                >
                  DONOR
                </span>
              </div>
            </div>
            {isAdminFlow && (
              <div
                className="absolute   p-2 px-8 rounded-2xl bg-gray-700 text-white right-10 bottom-10 cursor-pointer z-20"
                onClick={() => resetAddUsers()}
              >
                Back
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div className="relative bg-gray-100 h-screen">
      {!isAdminFlow && <CommonNavBar />}
      {renderRegisterContent()}
      {selectedRegistrationStep && selectedRegistrationStep !== "success" && (
        <div
          className="fixed z-50 p-2 px-8 rounded-2xl bg-gray-700 text-white right-10 bottom-10 cursor-pointer"
          onClick={() => setSelectedRegistrationStep("")}
        >
          Back
        </div>
      )}
    </div>
  );
};

export default Register;
